
import SectionActions from '@/components/plans/card/SectionActions.vue';
import SectionAttachments from '@/components/plans/card/SectionAttachments.vue';
import SectionGroup from '@/components/plans/card/SectionGroup.vue';
import SectionStandards from '@/components/plans/card/SectionStandards.vue';
import SectionStatuses from '@/components/plans/card/SectionStatuses.vue';
import SectionText from '@/components/plans/card/SectionText.vue';
import SectionTitle from '@/components/plans/card/SectionTitle.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
@Component({
  components: {
    SectionActions,
    SectionAttachments,
    SectionGroup,
    SectionStandards,
    SectionStatuses,
    SectionText,
    SectionTitle
  }
})
export default class LessonListItem extends Vue {
  @Prop({ type: Object })
  dayObject: any;

  @Prop({ type: Boolean, default: true })
  showDetails?: boolean;

  @Prop({ type: Boolean, default: true })
  clickable?: boolean;

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get localDayObject() {
    return this.dayObject || {
      color: this.$vuetify.theme.currentTheme.primary,
      style: {
        fontSize: '10pt',
        fontStyle: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
        color: '#000000',
        fontWeight: 'normal',
        textAlign: 'left',
        textDecoration: 'none',
        sections: []
      }
    };
  }

  get sections():any[] {
    return this.localDayObject.sections;
  }

  get color() {
    return CommonUtils.getColor(this.localDayObject.color);
  }
}
