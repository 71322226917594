var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "section",
        {
          staticClass: "section-card-content",
          style: { borderColor: _vm.color }
        },
        [
          _vm.section.hideLabel !== "Y"
            ? _c(
                "div",
                {
                  staticClass: "section-section-header",
                  style: _vm.section.labelStyle
                },
                [_vm._v(" " + _vm._s(_vm.section.label) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "section-section-content",
              style: _vm.section.textStyle
            },
            _vm._l(_vm.section.items, function(action, i) {
              return _c(
                "div",
                {
                  key: "section-action" + i,
                  staticClass: "section-action",
                  attrs: { url: action.url || "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.onAction(_vm.section, action)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(action.name) + " ")]
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }