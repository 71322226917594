
import PlanUtils from '@/utils/plan-utils';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');

@Component
export default class SectionText extends Vue {
  @Prop({ type: Object, required: true })
  section!: any;

  @Prop({ type: String, required: true, default: '#0057c1' })
  color!: string;

  @Prop({ type: Boolean, required: false, default: false })
  hover!: boolean;

  @Prop({ type: Boolean, required: true, default: true })
  showDetails!: boolean;

  @settings.Getter
  displaySectionTags!: boolean;

  get hasSectionText() {
    return CommonUtils.isHTMLNotEmpty(this.section.text);
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get show() {
    var isNotEmpty = false;
    var textItems = this.section.items;
    if (CommonUtils.isNotEmpty(textItems)) {
      for (var i in textItems) {
        if (CommonUtils.isHTMLNotEmpty(textItems[i].text)) {
          isNotEmpty = true;
          break;
        }
      }
    } else {
      isNotEmpty = CommonUtils.isHTMLNotEmpty(this.section.text) || this.section.textType === 'GC';
    }
    return this.showDetails && this.section.hide !== 'Y' && isNotEmpty;
  }

  get localSectionItems() {
    return (this.section.items || []).map((item: any) => {
      return {
        ...item,
        hasTag: this.hasTagLabel(item),
        tagLabel: this.getTagLabel(item)
      }
    })
  }

  get hasSectionItems() {
    return CommonUtils.isNotEmpty(this.localSectionItems);
  }

  getTagLabel(item: any) {
    return PlanUtils.getTagLabel(item);
  }

  hasTagLabel(item: any) {
    return this.displaySectionTags && CommonUtils.hasText(this.getTagLabel(item));
  }
}
