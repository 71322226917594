var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "section",
        {
          staticClass: "section-card-content",
          style: { borderColor: _vm.color },
        },
        [
          _vm.section.hideLabel !== "Y"
            ? _c(
                "div",
                {
                  staticClass: "section-section-header",
                  style: _vm.section.labelStyle,
                },
                [_vm._v(" " + _vm._s(_vm.section.label) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "section-section-content",
              style: _vm.section.textStyle,
            },
            [
              _c(
                "div",
                { staticClass: "section-section-list" },
                _vm._l(_vm.sectionItems, function (standard, i) {
                  return _c(
                    "div",
                    {
                      key: "section-items" + i,
                      staticClass: "section-items",
                      class: {
                        "no-description": _vm.section.hideItemName == "Y",
                        "section-tag": standard.hasTag,
                        "show-tag": _vm.section.showTag,
                        "no-expressions": standard.noExpressions,
                      },
                    },
                    [
                      _c("div", [
                        standard.hasTag
                          ? _c("div", [_vm._v(_vm._s(standard.tagLabel))])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "section-section-standard-item" },
                        [
                          _c("span", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.section.hideItemId !== "Y",
                                expression: "section.hideItemId !== 'Y'",
                              },
                            ],
                            domProps: { innerHTML: _vm._s(standard.id) },
                            on: {
                              mouseenter: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                _vm.displayToolTipStandardDetails &&
                                  _vm.showStandardsData($event, standard)
                              },
                              mouseleave: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.hideStandardsData.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                          _c("span", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.section.hideItemName !== "Y",
                                expression: "section.hideItemName !== 'Y'",
                              },
                            ],
                            domProps: { innerHTML: _vm._s(standard.name) },
                          }),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                absolute: "",
                "position-x": _vm.positionX,
                "position-y": _vm.positionY,
                "nudge-width": 100,
                "max-width": 400,
              },
              model: {
                value: _vm.isStandardsHovered,
                callback: function ($$v) {
                  _vm.isStandardsHovered = $$v
                },
                expression: "isStandardsHovered",
              },
            },
            [
              _c(
                "v-card",
                {
                  style: {
                    border:
                      "2px solid " + this.$vuetify.theme.currentTheme.primary,
                  },
                  attrs: { color: "#ffffff" },
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "black--text pa-0",
                      attrs: { justify: "center", align: "center" },
                    },
                    [
                      _vm.frameworkDataHaveData
                        ? _c(
                            "v-list",
                            { staticClass: "my-0 py-0" },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "my-0 py-0" },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "my-0 py-0" },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "my-0 py-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("frameworkLabel")
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "7" } },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(_vm.framework)),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "my-0 py-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("subjectLabel"))
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "7" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.frameworkSubjectDesc
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "my-0 py-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("gradeLevelLabel")
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "7" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.frameworkGradeLevel
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "my-0 py-0 mx-0" },
                                        [
                                          _c("v-col", {
                                            staticClass: "text-left",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.frameworkDesc
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.localShowOtherItemTypes
                        ? _c(
                            "v-list",
                            { staticClass: "my-0 py-0" },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "my-0 py-0 mx-0" },
                                    [
                                      _c("v-col", {
                                        staticClass: "text-left",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.localOtherItemTypeName
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("v-progress-circular", {
                            attrs: {
                              size: 25,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }