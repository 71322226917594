import ld from 'lodash';
import { sectionTagLabels } from './../constants';
import CommonUtils from './common-utils';

export default class PlanUtils {
  public static showItems(section: any) {
    return section.hide !== 'Y' && CommonUtils.isNotEmpty(section.items);
  }

  public static showLabel(section: any, label: string) {
    return section.hideLabel !== 'Y' && CommonUtils.hasText(label);
  }

  public static showText(section: any, text: string) {
    return section.hideText !== 'Y' && CommonUtils.hasText(text);
  }

  public static getTagLabel(item: any) {
    if (item.template) {
      return 'T';
    } else if (item.dataType) {
      return ld.get(sectionTagLabels, item.dataType);
    } else {
      return '';
    }
  }

  public static showTagLabel(item: any, section: any) {
    if (!section.showTag) {
      return false;
    } if (item.template) {
      return true;
    } else if (item.dataType) {
      return CommonUtils.hasText(ld.get(sectionTagLabels, item.dataType));
    } else {
      return false;
    }
  }
}
