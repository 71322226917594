
import CommonUtils from '@/utils/common-utils';
import PlanUtils from '@/utils/plan-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const classwork = namespace('classwork');
const settings = namespace('settings');
@Component
export default class SectionActions extends Vue {
  @Prop({ type: Object, required: true })
  section!: any;

  @Prop({ type: String, required: true, default: '#0057c1' })
  color!: string;

  @Prop({ type: Boolean, required: true, default: true })
  showDetails!: boolean;

  @classwork.Action
  getAssessment!: (params?: any) => Promise<any>;

  @classwork.Action
  getAssignment!: (params?: any) => Promise<any>;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter('getUserMode')
  userMode!: string;

  get show() {
    return this.showDetails && PlanUtils.showItems(this.section);
  }

  onAction(section:any, item:any) {
    if (section.section === 'assignments') {
      CommonUtils.showLoading();
      this.getAssignment({ assignmentId: item.id }).then((resp:any) => {
        const data = resp.resp.data;
        data.isAssignment = true;
        data.isReadOnly = this.userMode !== 'T' || (this.section.collaborateSubjectId > 0);
        data.classworkToggleDisable = true;
        this.$eventBus.$emit('openSubPage', {
          type: 'classwork',
          modal: true,
          input: {
            action: 'E',
            loadData: true,
            classwork: data
          }
        });
        CommonUtils.hideLoading();
      })
    } else if (section.section === 'assessments') {
      CommonUtils.showLoading();
      this.getAssessment({ assessmentId: item.id }).then((resp:any) => {
        const data = resp.resp.data;
        data.isAssignment = false;
        data.isReadOnly = this.userMode !== 'T' || (this.section.collaborateSubjectId > 0);
        data.classworkToggleDisable = true;
        this.$eventBus.$emit('openSubPage', {
          type: 'classwork',
          modal: true,
          input: {
            action: 'E',
            loadData: true,
            classwork: data
          }
        });
        CommonUtils.hideLoading();
      })
    }
  }
}
