var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "section",
        {
          staticClass: "section-title-content section-card-content",
          style: { borderColor: _vm.color },
        },
        [
          _vm.section.hideItemName !== "Y"
            ? _c(
                "div",
                _vm._l(_vm.filteredItems, function (status, i) {
                  return _c(
                    "div",
                    {
                      key: "section-status" + i,
                      staticClass: "section-status",
                      class: { "contrast-view": _vm.highContrastMode },
                      style: {
                        backgroundColor: _vm.getColor(status.color),
                        fontSize: _vm.fontSize,
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-check-circle" }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "inline-block",
                            "margin-left": "2px",
                          },
                        },
                        [_vm._v(_vm._s(status.name))]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c(
                "div",
                { staticClass: "section-status-circles" },
                _vm._l(_vm.filteredItems, function (status, i) {
                  return _c("i", {
                    key: "section-status-circles" + i,
                    staticClass: "fas fa-check-circle",
                    style: {
                      color: _vm.getColor(status.color),
                      fontSize: _vm.fontSize,
                    },
                  })
                }),
                0
              ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }