var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "section",
        {
          staticClass: "section-card-content",
          style: { borderColor: _vm.color }
        },
        [
          _vm.section.hideLabel !== "Y"
            ? _c(
                "div",
                {
                  staticClass: "section-section-header",
                  style: _vm.section.labelStyle
                },
                [_vm._v(" " + _vm._s(_vm.section.label) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "section-section-content",
              style: _vm.section.textStyle
            },
            _vm._l(_vm.sectionItems, function(attachment, i) {
              return _c(
                "div",
                {
                  key: "section-attachments" + i,
                  staticClass: "section-items",
                  class: {
                    "section-tag": attachment.hasTag,
                    "show-tag": _vm.section.showTag
                  }
                },
                [
                  _c("div", [
                    attachment.hasTag
                      ? _c("div", [
                          _vm._v(" " + _vm._s(attachment.tagLabel) + " ")
                        ])
                      : _vm._e()
                  ]),
                  _c("div", [
                    attachment.url
                      ? _c(
                          "a",
                          {
                            staticClass: "planbook-attachment",
                            attrs: { target: "_blank", href: attachment.url }
                          },
                          [_vm._v(_vm._s(attachment.formattedName))]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "planbook-attachment",
                            staticStyle: { "text-decoration": "underline" },
                            attrs: { target: "_blank" }
                          },
                          [_vm._v(_vm._s(attachment.formattedName))]
                        )
                  ])
                ]
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }