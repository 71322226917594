
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');

@Component
export default class SectionStatuses extends Vue {
  @Prop({ type: Object, required: true })
  section!: any;

  @Prop({ type: String, required: true, default: '#0057c1' })
  color!: string;

  @Prop({ type: Boolean, required: false, default: false })
  hover!: boolean;

  @Prop({ type: Boolean, required: true, default: true })
  showDetails!: boolean;

  @settings.Getter
  highContrastMode!: boolean;

  get getColor() {
    return CommonUtils.getColor;
  }

  get fontSize() {
    try {
      return (this.section.labelStyle.fontSize || 10) + 'pt';
    } catch (e) {
      return '10pt'
    }
  }

  get show() {
    return this.showDetails && CommonUtils.isNotEmpty(this.section.items);
  }

  get filteredItems() {
    return (this.section.items as Array<any>).filter(item => {
      return (
        item.visible && ((item.type === 'teacher' || this.section.displaySchoolStatus) && (CommonUtils.isEmpty(this.section.filterItems) || CommonUtils.isIn(item.id, this.section.filterItems)))
      );
    });
  }
}
