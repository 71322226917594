var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "section-group" }, [
        _c("h4", { style: { "border-bottom-color": _vm.color } }, [
          _c("span", { style: { color: _vm.color } }, [
            _vm._v(_vm._s(_vm.section.name))
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }