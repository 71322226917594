





































































































import CommonUtils from '@/utils/common-utils';
import PlanUtils from '@/utils/plan-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const standards = namespace('standards');
const settings = namespace('settings');

@Component
export default class SectionStandards extends Vue {
  @Prop({ type: Object, required: true })
  section!: any;

  @Prop({ type: String, required: true, default: '#0057c1' })
  color!: string;

  @Prop({ type: Boolean, required: false, default: false })
  hover!: boolean;

  @Prop({ type: Boolean, required: true, default: true })
  showDetails!: boolean;

  @Prop({ type: Number, required: false, default: 0 })
  teacherId!: number;

  isStandardsHovered = false;
  positionX = 0;
  positionY = 0;
  localOtherItemTypeId = '';
  localOtherItemTypeName = '';
  localShowOtherItemTypes = false;

  @standards.Getter('getHoveredStandardsData')
  hoveredStandardsData!: any;

  @standards.Getter('getFrameworkGradeLevel')
  frameworkGradeLevel!: any;

  @standards.Getter('getFrameworkDesc')
  frameworkDesc!: any;

  @standards.Getter('getFramework')
  framework!: any;

  @standards.Getter('getFrameworkSubjectDesc')
  frameworkSubjectDesc!: any;

  @standards.Action('getStandardData')
  getStandardData!: (params?: any) => Promise<any>;

  @standards.Mutation
  setFrameworkData!: (value: any) => void;

  @standards.Mutation
  resetFrameworkData!: (value: any) => void;

  @standards.Mutation
  setHoveredStandardsData!: (value: any) => void;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getOtherSettings')
  otherSettings!: any;

  @settings.Getter
  displaySectionTags!: boolean;

  get sectionItems() {
    return (this.section.items || []).sort((s1: any, s2: any) => {
      const id1 = s1?.id || '';
      const id2 = s2?.id || '';
      if ((CommonUtils.hasText(id1) && CommonUtils.hasText(id2)) ||
         (CommonUtils.hasText(id1) && CommonUtils.hasNoText(id2)) ||
         (CommonUtils.hasNoText(id1) && CommonUtils.hasText(id2))) {
        return id1.localeCompare(id2);
      }
      return (s1.name || '').localeCompare((s2.name || ''));
    }).map((item: any) => {
      return {
        ...item,
        hasTag: this.hasTagLabel(item),
        tagLabel: this.getTagLabel(item)
      }
    });
  }

  get standardsData() {
    return this.hoveredStandardsData;
  }

  get show() {
    return this.showDetails && (this.section.hideItemId !== 'Y' || this.section.hideItemName !== 'Y') && CommonUtils.isNotEmpty(this.section.items);
  }

  get frameworkDataHaveData() {
    if (this.framework !== '' && this.frameworkGradeLevel !== '' && this.frameworkSubjectDesc !== '') {
      return true;
    } else {
      return false;
    }
  }

  get currentTeacherId(): number {
    if (this.userMode === 'T') {
      return +this.$store.state.settings.userId;
    } else if (this.$currentUser.isStudent) {
      return this.teacherId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  get displayToolTipStandardDetails() {
    return this.otherSettings.displayToolTipStandardDetails;
  }

  getTagLabel(item: any) {
    return PlanUtils.getTagLabel(item);
  }

  hasTagLabel(item: any) {
    return this.displaySectionTags && CommonUtils.hasText(this.getTagLabel(item));
  }

  showStandardsData(evt:any, data:any) {
    const that = this;
    if (data.itemType === 'strategy' || data.itemType === 'standard') {
      this.resetFrameworkData('');
      this.positionX = evt.clientX + 5;
      this.positionY = evt.clientY;
      this.isStandardsHovered = true;
      const found = that.standardsData.some((e:any) => +e.id === +data.dbId);
      if (!found) {
        this.getStandardData({
          type: data.itemType.toUpperCase(),
          id: data.dbId,
          teacherId: this.currentTeacherId,
          apiKey: this.$currentUser.isStudent && this.$currentUser.id === 0 ? '2e6c6353-3891-4e8a-b579-f6ef074dea34' : undefined
        }).then((resp:any) => {
          const respData = resp.data;
          const found = that.standardsData.some((e:any) => +e.id === +respData.id);
          if (!found) {
            that.setHoveredStandardsData({
              id: respData.id,
              framework: respData.frameworkDesc,
              desc: respData.desc,
              gradeLevel: respData.gradeLevel,
              subjectDesc: respData.subjectDesc
            });
          }
          if (this.isStandardsHovered) {
            const standardsData:any[] = that.standardsData.filter((e:any) => +e.id === +data.dbId);
            this.setFrameworkData({
              framework: standardsData[0].framework,
              desc: standardsData[0].desc,
              gradeLevel: standardsData[0].gradeLevel,
              subjectDesc: standardsData[0].subjectDesc
            });
          }
        });
      } else {
        const standardsData:any[] = that.standardsData.filter((e:any) => +e.id === +data.dbId);
        this.setFrameworkData({
          framework: standardsData[0].framework,
          desc: standardsData[0].desc,
          gradeLevel: standardsData[0].gradeLevel,
          subjectDesc: standardsData[0].subjectDesc
        });
      }
    } else if (!data.itemType) {
      this.positionX = evt.clientX + 5;
      this.positionY = evt.clientY;
      this.isStandardsHovered = true;
      this.localShowOtherItemTypes = true;
      this.localOtherItemTypeId = data.id;
      this.localOtherItemTypeName = data.name;
    }
  }

  hideStandardsData() {
    this.isStandardsHovered = false;
    this.resetFrameworkData('');
    this.reset();
  }

  reset() {
    this.localShowOtherItemTypes = false;
    this.localOtherItemTypeId = '';
    this.localOtherItemTypeName = '';
  }
}
