var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "section",
        {
          staticClass: "section-title-content section-card-content",
          style: _vm.section.textStyle,
        },
        [
          _vm.hasTitleItems
            ? _c(
                "div",
                _vm._l(_vm.localSectionItems, function (item, i) {
                  return _c(
                    "div",
                    {
                      key: "section-title-items" + i,
                      staticClass: "section-items section-title",
                      class: {
                        "section-tag": item.hasTag,
                        "show-tag": _vm.section.showTag,
                      },
                      attrs: { "data-type": _vm.section.dataType },
                    },
                    [
                      _c("div", [
                        item.hasTag
                          ? _c("div", [_vm._v(_vm._s(item.tagLabel))])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "section-item section-item-title",
                          style: {
                            cursor: ["linkedUnit", "unit"].includes(
                              item.dataType
                            )
                              ? "pointer"
                              : "auto",
                          },
                          attrs: { "data-type": item.dataType },
                        },
                        [
                          _vm.showLabel(item.label)
                            ? _c(
                                "div",
                                {
                                  staticClass: "section-unit-title",
                                  style: {
                                    backgroundColor:
                                      item.labelBackgroundColor || _vm.color,
                                    color:
                                      item.labelBackgroundColor &&
                                      _vm.isColorReadable(
                                        "white",
                                        item.labelBackgroundColor
                                      )
                                        ? "white"
                                        : "black",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.userType !== "S"
                                          ? "section-unit-label"
                                          : "",
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  ),
                                  _vm.hasText(item.description)
                                    ? _c("i", {
                                        staticClass:
                                          "section-unit-title-info ml-1 fa-light fa-circle-info",
                                        on: {
                                          mouseenter: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.infoMouseEnter(
                                              $event,
                                              item
                                            )
                                          },
                                          mouseleave: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.infoMouseLeave.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(_vm.showText(item.text) ? item.text : "") +
                              " "
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("div", [
                _vm.showLabel(_vm.section.label)
                  ? _c(
                      "div",
                      {
                        staticClass: "section-title section-unit-title",
                        style: { backgroundColor: _vm.color },
                        attrs: { "data-type": _vm.section.dataType },
                      },
                      [_vm._v(" " + _vm._s(_vm.section.label) + " ")]
                    )
                  : _vm._e(),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.showText(_vm.section.text) ? _vm.section.text : ""
                    ) +
                    " "
                ),
              ]),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                absolute: "",
                "position-x": _vm.descPositionX,
                "position-y": _vm.descPositionY,
                "max-width": 400,
              },
              model: {
                value: _vm.showDesc,
                callback: function ($$v) {
                  _vm.showDesc = $$v
                },
                expression: "showDesc",
              },
            },
            [
              _c(
                "v-card",
                {
                  style: {
                    border:
                      "2px solid " + this.$vuetify.theme.currentTheme.primary,
                  },
                  attrs: { color: "#ffffff" },
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "black--text pa-1",
                      attrs: { justify: "center", align: "center" },
                    },
                    [
                      _c("div", {
                        staticClass: "section-description",
                        domProps: { innerHTML: _vm._s(_vm.description) },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }