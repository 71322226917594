var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "section",
        {
          staticClass: "section-card-content",
          style: { borderColor: _vm.color }
        },
        [
          _vm.section.hideLabel !== "Y"
            ? _c(
                "div",
                {
                  staticClass: "section-section-header",
                  style: _vm.section.labelStyle
                },
                [_vm._v(" " + _vm._s(_vm.section.label) + " ")]
              )
            : _vm._e(),
          _vm.hasSectionItems
            ? _c(
                "div",
                {
                  staticClass: "section-section-content",
                  style: _vm.section.textStyle
                },
                _vm._l(_vm.localSectionItems, function(textItem, i) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasText(textItem.text),
                          expression: "hasText(textItem.text)"
                        }
                      ],
                      key: "section-item" + i,
                      staticClass: "section-items",
                      class: {
                        "section-tag": textItem.hasTag,
                        "show-tag": _vm.section.showTag
                      },
                      staticStyle: { width: "100%" },
                      style:
                        _vm.section.textStyle.color.toLowerCase() === "#ffffff"
                          ? "color: black;"
                          : ""
                    },
                    [
                      _c("div", [
                        textItem.hasTag
                          ? _c("div", [_vm._v(_vm._s(textItem.tagLabel))])
                          : _vm._e()
                      ]),
                      _c("div", {
                        directives: [
                          { name: "linkified", rawName: "v-linkified" }
                        ],
                        domProps: { innerHTML: _vm._s(textItem.text) }
                      })
                    ]
                  )
                }),
                0
              )
            : _vm.hasSectionText
            ? _c("div", {
                directives: [{ name: "linkified", rawName: "v-linkified" }],
                staticClass: "section-section-content",
                style: _vm.section.textStyle,
                domProps: { innerHTML: _vm._s(_vm.section.text) }
              })
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }