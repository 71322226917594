








import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SectionGroup extends Vue {
  @Prop({ type: Object, required: true })
  section!: any;

  @Prop({ type: String, required: true, default: '#0057c1' })
  color!: string;

  @Prop({ type: Boolean, required: true, default: true })
  showDetails!: boolean;

  get show() {
    return this.color && this.section && CommonUtils.hasText(this.section.name);
  }
}
