
import PlanUtils from '@/utils/plan-utils';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const units = namespace('units');
const settings = namespace('settings');

@Component
export default class SectionTitle extends Vue {
  @Prop({ type: Object, required: true })
  section!: any;

  @Prop({ type: String, required: true, default: '#0057c1' })
  color!: string;

  @Prop({ type: Boolean, required: false, default: false })
  hover!: boolean;

  @Prop({ type: Boolean, required: true, default: true })
  showDetails!: boolean;

  showDesc = false;
  description = '';
  descPositionX = 0;
  descPositionY = 0;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter
  displaySectionTags!: boolean;

  get hasTitleItems() {
    return CommonUtils.isNotEmpty(this.localSectionItems);
  }

  get show() {
    return this.section.hide !== 'Y' && this.showTitle;
  }

  get showTitle() {
    if (this.hasTitleItems) {
      for (var i in this.section.items) {
        var item = this.section.items[i];
        if (
          PlanUtils.showLabel(this.section, item.label) ||
          PlanUtils.showText(this.section, item.text)
        ) {
          return true;
        }
      }
      return false;
    } else {
      return (
        PlanUtils.showLabel(this.section, this.section.label) ||
        PlanUtils.showText(this.section, this.section.text)
      );
    }
  }

  get localSectionItems() {
    return (this.section.items || []).map((item: any) => {
      return {
        ...item,
        hasTag: this.hasTagLabel(item),
        tagLabel: this.getTagLabel(item)
      }
    })
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  isColorReadable(fg: string, bg?: string) {
    return CommonUtils.isColorReadable(fg, bg);
  }

  showLabel(label: string) {
    return PlanUtils.showLabel(this.section, label);
  }

  showText(text: string) {
    return PlanUtils.showText(this.section, text);
  }

  getTagLabel(item: any) {
    return PlanUtils.getTagLabel(item);
  }

  hasTagLabel(item: any) {
    return this.displaySectionTags && CommonUtils.hasText(this.getTagLabel(item));
  }

  infoMouseEnter(event: MouseEvent, item: any) {
    this.showDesc = true;
    this.description = item.description;
    this.descPositionX = event.clientX;
    this.descPositionY = event.clientY;
  }

  infoMouseLeave() {
    this.showDesc = false;
  }
}
