var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lesson-list-item" }, [
    _c(
      "div",
      { staticClass: "section-card-contents" },
      _vm._l(_vm.sections, function(section, i) {
        return _c(section.type, {
          key: "section-card-content" + i,
          tag: "component",
          attrs: {
            showDetails: _vm.showDetails,
            section: section,
            color: _vm.color
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }