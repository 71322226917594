
import CommonUtils from '@/utils/common-utils';
import PlanUtils from '@/utils/plan-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const settings = namespace('settings');

@Component
export default class SectionAttachments extends Vue {
  @Prop({ type: Object, required: true })
  section!: any;

  @Prop({ type: String, required: true, default: '#0057c1' })
  color!: string;

  @Prop({ type: Boolean, required: false, default: false })
  hover!: boolean;

  @Prop({ type: Boolean, required: true, default: true })
  showDetails!: boolean;

  @settings.Getter
  displaySectionTags!: boolean;

  getAttachmentName(item: any) {
    return item.name + (item.isPrivate ? ' (Private)' : '');
  }

  get sectionItems() {
    return (this.section.items || []).sort((a1: any, a2: any) => {
      const a1Name = a1.name?.trim() || '';
      const a2Name = a2.name?.trim() || '';
      return a1Name.localeCompare(a2Name);
    }).map((item: any) => {
      return {
        ...item,
        hasTag: this.hasTagLabel(item),
        tagLabel: this.getTagLabel(item),
        formattedName: this.getAttachmentName(item),
        url: this.cleanAttachmentUrl(item.url || '')
      }
    });
  }

  get show() {
    return this.showDetails && PlanUtils.showItems(this.section);
  }

  getTagLabel(item: any) {
    return PlanUtils.getTagLabel(item);
  }

  hasTagLabel(item: any) {
    return this.displaySectionTags && CommonUtils.hasText(this.getTagLabel(item));
  }

  cleanAttachmentUrl(url: string) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = url;
    return textArea.value;
  }
}
